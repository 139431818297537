// Admin Auth
export const ADMIN_LOGIN_DETAILS = "Account/Login";
export const ADMIN_FORGOT_PASSWORD = "Account/ForgotPassword";
export const ADMIN_RESET_PASSWORD = "Account/ResetPassword";

//Event handlers
export const ADD_NEW_EVENT = "Event/AddEvent";
export const GET_EVENT_BY_ID = "Event/GetEventById";
export const GET_ALL_EVENT = "Event/GetAllEvent";
export const UPDATE_EVENT = "Event/UpdateEvent";
export const DELETE_EVENT = "Event/DeleteEvent";
export const ENABLE_OR_DISABLE_EVENT = "Event/EnableOrDisableEvent";
