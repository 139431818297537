import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../Components/AdminLayout/AdminLayout";
import { DatePicker, Input, Upload, message, Form } from "antd";
import "./AddEvents.scss";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import Buttons from "../../Components/Buttons/Buttons";
import { getApi, postApi } from "../../utils/apiService";
import {
  ADD_NEW_EVENT,
  GET_EVENT_BY_ID,
  UPDATE_EVENT,
} from "../../utils/apiPath";
import { errorToast, successToast } from "../../services/ToastHelper";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import JoditEditor from "jodit-react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
// import TableUI from 'quill-table-ui';
// import linkedin from '../../assets/images/linked_in.png';
// import facebook from '../../assets/images/facebook.png';
// import instagram from '../../assets/images/instagram-img.png';
// import youtube from '../../assets/images/youtube.png';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => {
    const base64Data = reader.result.split(",")[1];
    callback(base64Data);
  });
  reader.readAsDataURL(img);
};

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const initialvalues = {
  title: "",
  startDate: "",
  endDate: "",
  shortDescription: "",
  longDescription: "",
  image: "",
  additionalInfo: "",
};

// const defaultSignature = `
//   <p>Register yourself, gain a wealth of new knowledge and get certified!</p>
//   <div>
//     <a href="https://www.linkedin.com/company/77741703/admin/feed/posts/" target="_blank" rel="noopener noreferrer">
//       <img src="${linkedin}" alt="LinkedIn" style="width: 24px; height: 24px;" />
//     </a>
//     <a href="https://www.facebook.com/profile.php?id=61555848406435" target="_blank" rel="noopener noreferrer">
//       <img src="${facebook}" alt="Facebook" style="width: 24px; height: 24px;" />
//     </a>
//     <a href="https://www.instagram.com/astakenis/" target="_blank" rel="noopener noreferrer">
//       <img src="${instagram}" alt="Instagram" style="width: 24px; height: 24px;" />
//     </a>
//     <a href="http://www.youtube.com/@AstakenisConsulting" target="_blank" rel="noopener noreferrer">
//       <img src="${youtube}" alt="YouTube" style="width: 24px; height: 24px;" />
//     </a>
//   </div>

// `;

const AddEvents = () => {
  const [eventData, setEventData] = useState(initialvalues);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [eventPatchData, setEeventPatchData] = useState({});
  const [content, setContent] = useState("");
  const editor = useRef(null);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { eventId } = state || {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleDateChange = (dates, dateStrings) => {
    const formattedStartDate = dayjs(dateStrings[0]).format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    const formattedEndDate = dayjs(dateStrings[1]).format(
      "YYYY-MM-DDTHH:mm:ss"
    );

    setEventData({
      ...eventData,
      dateRange: [formattedStartDate, formattedEndDate],
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });
  };

  const handleImageChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (base64) => {
        setLoading(false);
        setImageUrl(`data:${info.file.type};base64,${base64}`);
        setEventData({
          ...eventData,
          image: base64,
        });
      });
    }
  };

  const customUpload = ({ file, onSuccess }) => {
    onSuccess("ok");
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const validateFields = () => {
    let errObj = { ...initialvalues };

    if (!eventData.title) {
      errObj.title = "Event Title is Required";
    }
    if (!eventData.startDate || !eventData.endDate) {
      errObj.date = "Event Dates are Required";
    }
    if (!eventData.shortDescription) {
      errObj.shortDescription = "Short Description is Required";
    }
    setErrors((prev) => ({ ...prev, ...errObj }));
    const data = Object.values(errObj).every((x) => x === "" || x === null);
    return data;
  };

  const handleEventSubmit = async () => {
    if (validateFields()) {
      setIsLoading(true);

      const payload = {
        eventTitle: eventData.title,
        fromDate: eventData.startDate,
        toDate: eventData.endDate,
        shortDescription: eventData.shortDescription,
        longDescription: eventData.longDescription,
        imageUrl: eventData.image,
        additionalInformation: content,
        isEnable: true,
      };

      let apiEndpoint;
      if (eventId) {
        apiEndpoint = UPDATE_EVENT;
        payload.eventId = eventId;
      } else {
        apiEndpoint = ADD_NEW_EVENT;
      }

      const { statusCode, message, error } = await postApi(
        apiEndpoint,
        payload
      );

      if (statusCode === 200) {
        setIsLoading(false);
        successToast(message);
        navigate("/event-details");
      } else {
        setIsLoading(false);
        errorToast(error);
      }
    }
  };

  const fetchEventDataByID = async () => {
    const { statusCode, error, data } = await getApi(GET_EVENT_BY_ID, {
      params: { eventId: eventId },
    });
    if (statusCode === 200) {
      setEeventPatchData(data);
      setEventData({
        title: data.eventTitle,
        startDate: data.fromDate,
        endDate: data.toDate,
        shortDescription: data.shortDescription,
        longDescription: data.longDescription,
        image: data.imageUrl,
        additionalInfo: data.additionalInformation,
      });
      setImageUrl(data.imageUrl);
      setContent(data.additionalInformation);
    } else {
      errorToast(error);
    }
  };

  useEffect(() => {
    if (eventId) {
      fetchEventDataByID();
    } else {
      setContent("");
    }
  }, [eventId]);

  useEffect(() => {
    if (editor.current) {
      editor.current.value = content;
    }
  }, [content]);

  const handleCancel = () => {
    setEventData(initialvalues);
    setErrors({});
    setImageUrl(null);
    setContent("");
  };

  const handleEditorClear = () => {
    setContent("");
  };

  const config = {
    toolbarAdaptive: false,
    readonly: false,
    placeholder: "Write Your Message Here",
    showTooltip: true,
    cleanHTML: true,
    spellcheck: true,
    observer: false,
    height: 500,
    buttons: [
      "paragraph",
      "font",
      "brush",
      "|",
      "eraser",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "left",
      "center",
      "right",
      "|",
      "table",
      "source",
      "image",
      "link",
      "video",
      "file",
      "print",
      "|",
      "hr",
      "preview",
      "|",
    ],
    uploader: {
      insertImageAsBase64URI: true,  
      isSuccessResponse: (resp) => resp.success, 
      url: '/upload-handler',  
      process: (formData, name) => {
        return formData;
      },
      error: (e) => {
        console.error(e.message); 
      }
    }
  };
  

  return (
    <AdminLayout>
      <div className="add">
        <div className="add_sec">
          <div className="add_sec_header">
            <h2>{eventId ? "Update Event" : "Add New Event"}</h2>
          </div>
          <div className="add_sec_form">
            <div className="add_sec_form_top">
              <div className="add_sec_form_top_left">
                <div className="add_sec_form_top_left_inputs">
                  <Form.Item label="Event Title*" layout="vertical">
                    <Input
                      placeholder="Enter Event Title"
                      onChange={handleChange}
                      name="title"
                      value={eventData.title}
                      size="large"
                    />
                    <div>
                      {errors.title && (
                        <span className="error">{errors.title}</span>
                      )}
                    </div>
                  </Form.Item>
                </div>
                <div className="add_sec_form_top_left_inputs">
                  <Form.Item label="Short Description*" layout="vertical">
                    <TextArea
                      placeholder="Enter Short Description"
                      onChange={handleChange}
                      name="shortDescription"
                      value={eventData.shortDescription}
                      style={{
                        height: 100,
                      }}
                      size="large"
                    />
                    <div>
                      {errors.shortDescription && (
                        <span className="error">{errors.shortDescription}</span>
                      )}
                    </div>
                  </Form.Item>
                </div>
              </div>
              <div className="add_sec_form_top_right">
                <div className="add_sec_form_top_right_inputs">
                  <Form.Item label="Select Event Date&Time*" layout="vertical">
                    <RangePicker
                      showTime
                      onChange={handleDateChange}
                      style={{ width: "100%" }}
                      size="large"
                      value={
                        eventData.startDate && eventData.endDate
                          ? [
                              dayjs(eventData.startDate),
                              dayjs(eventData.endDate),
                            ]
                          : []
                      }
                    />
                    <div>
                      {errors.date && (
                        <span className="error">{errors.date}</span>
                      )}
                    </div>
                  </Form.Item>
                </div>
                <div className="add_sec_form_top_right_inputs">
                  <Form.Item label="Upload Images" layout="vertical">
                    <Upload
                      name="avatar"
                      listType="picture-card"
                      className="avatar-uploader"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleImageChange}
                      customRequest={customUpload}
                    >
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </Upload>
                    <div>
                      {errors.image && (
                        <span className="error">{errors.image}</span>
                      )}
                    </div>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="add_sec_form_bottom">
              <label> Additional Information</label>
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                tabIndex={1}
                onBlur={(newContent) => setContent(newContent)}
                onChange={(newContent) => {}}
              />
            </div>
          </div>
          <div className="add_sec_buttons">
            <div className="add_sec_buttons_cont">
              <Buttons
                variant="secondary"
                onClick={handleCancel}
                loading={isLoading}
              >
                Cancel
              </Buttons>
              <Buttons
                variant="primary"
                onClick={handleEventSubmit}
                loading={isLoading}
              >
                {eventId ? "Update" : "Submit"}
              </Buttons>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AddEvents;
