import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/Login/Login";
import AddEvents from "../pages/AddEvents/AddEvents";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import EventDetails from "../pages/EventDetails/EventDetails";
import ProtectedRoute from "./ProtectedRoute";
import LinkPage from "../pages/LinkPage/LinkPage";

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/add-events" element={<AddEvents />} />
        <Route path="/event-details" element={<ProtectedRoute><EventDetails /></ProtectedRoute>} />
        <Route path="/event-link" element={<ProtectedRoute><LinkPage /></ProtectedRoute>} />
  
      </Routes>
    </BrowserRouter>
  );
};

export default RouterComponent;
