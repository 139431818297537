import React from "react";
import AdminLayout from "../../Components/AdminLayout/AdminLayout";
import { useLocation } from "react-router-dom";
import "./LinkPage.scss";

const LinkPage = () => {
  const { state } = useLocation();
  const { eventData } = state || {};

  return (
    <AdminLayout>
      <div className="lp">
        <div className="custom-table" dangerouslySetInnerHTML={{ __html: eventData?.additionalInformation }} />
      </div>
    </AdminLayout>
  );
};

export default LinkPage;
