export const EmailIcon = ({ color = "#8f8f8f", className }) => {
  return (
    <svg
      className={className}
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4766 0H1.52344C0.683414 0 0 0.747667 0 1.66667V8.33333C0 9.25233 0.683414 10 1.52344 10H11.4766C12.3166 10 13 9.25233 13 8.33333V1.66667C13 0.747667 12.3166 0 11.4766 0ZM11.2762 1.11111L6.86225 6.02617C6.76741 6.13178 6.63536 6.19236 6.5 6.19236C6.36464 6.19236 6.23261 6.13178 6.13775 6.02617L1.72385 1.11111H11.2762ZM11.9844 8.33333C11.9844 8.63967 11.7566 8.88889 11.4766 8.88889H1.52344C1.24343 8.88889 1.01562 8.63967 1.01562 8.33333V1.90792L5.41328 6.80483C5.69783 7.12172 6.09395 7.30347 6.5 7.30347C6.90605 7.30347 7.30217 7.12172 7.58672 6.80486L11.9844 1.90794V8.33333Z"
        fill="#989898"
      />
    </svg>
  );
};

export const PasswordLock = ({ color = "#8f8f8f", className }) => {
  return (
    <svg
      className={className}
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.15625 4.40625H7.31163V2.47898C7.31163 1.11206 6.17611 0 4.78038 0C3.38466 0 2.24913 1.11206 2.24913 2.47898V4.40625H1.40625C0.630844 4.40625 0 5.03709 0 5.8125V10.5938C0 11.3692 0.630844 12 1.40625 12H8.15625C8.93166 12 9.5625 11.3692 9.5625 10.5938V5.8125C9.5625 5.03709 8.93166 4.40625 8.15625 4.40625ZM3.18663 2.47898C3.18663 1.629 3.90159 0.9375 4.78038 0.9375C5.65917 0.9375 6.37413 1.629 6.37413 2.47898V4.40625H3.18663V2.47898ZM8.625 10.5938C8.625 10.8522 8.41472 11.0625 8.15625 11.0625H1.40625C1.14778 11.0625 0.9375 10.8522 0.9375 10.5938V5.8125C0.9375 5.55403 1.14778 5.34375 1.40625 5.34375H8.15625C8.41472 5.34375 8.625 5.55403 8.625 5.8125V10.5938Z"
        fill="#989898"
      />
      <path
        d="M4.78125 6.70312C4.3023 6.70312 3.91406 7.09137 3.91406 7.57031C3.91406 7.87612 4.0725 8.14477 4.31163 8.29917V9.32812C4.31163 9.58699 4.52149 9.79688 4.78038 9.79688C5.03925 9.79688 5.24913 9.58699 5.24913 9.32812V8.30027C5.48923 8.14605 5.64844 7.87688 5.64844 7.57031C5.64844 7.09137 5.2602 6.70312 4.78125 6.70312Z"
        fill="#989898"
      />
    </svg>
  );
};

export const SearchIcon = ({ color }) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7871 13.6626L10.3359 9.94962C11.2233 8.85844 11.7095 7.48552 11.7095 6.05624C11.7095 2.71688 9.08299 0 5.85474 0C2.62649 0 0 2.71688 0 6.05624C0 9.3956 2.62649 12.1125 5.85474 12.1125C7.06667 12.1125 8.22158 11.7344 9.20899 11.0166L12.6864 14.7578C12.8318 14.9139 13.0273 15 13.2368 15C13.4351 15 13.6232 14.9218 13.766 14.7796C14.0694 14.4776 14.0791 13.9768 13.7871 13.6626ZM5.85474 1.57989C8.24092 1.57989 10.1821 3.58793 10.1821 6.05624C10.1821 8.52456 8.24092 10.5326 5.85474 10.5326C3.46855 10.5326 1.52732 8.52456 1.52732 6.05624C1.52732 3.58793 3.46855 1.57989 5.85474 1.57989Z"
        fill="#707070"
      />
    </svg>
  );
};

export const PersonIcon = () => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="55" height="55" rx="8" fill="#104581" />
      <path
        d="M27.5 27.5C28.9397 27.5 30.3205 26.9469 31.3386 25.9623C32.3566 24.9777 32.9286 23.6424 32.9286 22.25C32.9286 20.8576 32.3566 19.5223 31.3386 18.5377C30.3205 17.5531 28.9397 17 27.5 17C26.0603 17 24.6795 17.5531 23.6614 18.5377C22.6434 19.5223 22.0714 20.8576 22.0714 22.25C22.0714 23.6424 22.6434 24.9777 23.6614 25.9623C24.6795 26.9469 26.0603 27.5 27.5 27.5ZM25.5618 29.4688C21.3844 29.4688 18 32.7418 18 36.7818C18 37.4545 18.5641 38 19.2596 38H35.7404C36.4359 38 37 37.4545 37 36.7818C37 32.7418 33.6156 29.4688 29.4382 29.4688H25.5618Z"
        fill="white"
      />
    </svg>
  );
};

export const PlusIcon = () => {
  return (
    <svg
      fill="#FFF"
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width="12px"
      height="12px"
      viewBox="0 0 45.402 45.402"
    >
      <g>
        <path
          d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141
		c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27
		c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435
		c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"
        />
      </g>
    </svg>
  );
};

export const BackArrow = ({ color }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
        fill="#104581"
      />
      <path
        d="M20.3143 15H9.6857"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 9.68579L9.6857 15.0001L15 20.3144"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
